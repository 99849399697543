import { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { IoAddCircleSharp } from 'react-icons/io5'
import { getTouchByDate } from './templeData'
import { TouchesContext } from '../context/TouchesContext'

const DataKey = ({ modalIsOpen, setIsModalOpen }) => {
  const { touches, setTouches, currentDate, setCurrentDate } = useContext(TouchesContext);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getTouchByDate(currentDate)
      setTouches(data)
    }
    fetchData()
  }, [currentDate])

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  return (
    <div className="dataKey">
      <div className="monthSelect">
        <label htmlFor="month">Month:</label>
        <select
          id="month"
          value={currentDate}
          onChange={(e) => setCurrentDate(parseInt(e.target.value))}
        >
          <option value="">Select Month</option>
          {months.map((month, index) => (
            <option key={index} value={index + 1}>
              {month}
            </option>
          ))}
        </select>
      </div>
      <div className="monthCount">
        <p>
          Number of temple visits in {months[currentDate - 1]} 2025:{' '}
          {touches?.total_count}
        </p>
      </div>
      <button onClick={() => setIsModalOpen(true)}>
        <IoAddCircleSharp />
      </button>
    </div>
  )
}

DataKey.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
}

export default DataKey
