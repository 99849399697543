import React, { useState, useRef, useEffect } from 'react'
import DataPoints from './assets/DataPoints'
import RedCliffsImage from '@/assets/images/Red-Cliffs-Temple-September-2023.jpeg'
import AddTouch from './assets/AddTouch'
import { getTempleData, getTouchData } from './assets/templeData'
import '@/styles/index.scss'
import DataKey from './assets/DataKey'

function App() {
  const divRef = useRef(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [temples, setTemples] = useState(null)
  const [touchData, setTouchData] = useState(null)
  const [loadAddTouch, setLoadAddTouch] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      if (!temples) {
        const data = await getTempleData()
        setTemples(data)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (!touchData || loadAddTouch === false) {
        const touchData = await getTouchData()
        setTouchData(touchData)
      }
    }

    fetchData()
  }, [loadAddTouch])

  return (
    <>
      <div className="header">
        <h1>Buena Vista Temple Touches</h1>
      </div>
      <div className="templeImage" ref={divRef}></div>
      <DataPoints
        data={touchData || []}
        divRef={divRef}
        temples={temples || []}
      />
      <DataKey 
        isModalOpen={isModalOpen} 
        setIsModalOpen={setIsModalOpen}
      />
      {isModalOpen && (
        <AddTouch
          handleCloseModal={() => setIsModalOpen(false)}
          temples={temples}
          load={loadAddTouch}
          setLoad={setLoadAddTouch}
        />
      )}
    </>
  )
}

export default App
