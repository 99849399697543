import axios from 'axios'

const getBaseUrl = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:5000'
  } else {
    return 'https://cleanpages.net' // Replace with your production URL
  }
}

export const getTouchData = async () => {
  try {
    const response = await axios.get(`${getBaseUrl()}/api/touches`)
    return response.data
  } catch (error) {
    console.log('Error fetching touch data:', error)
  }
}

export const getTempleData = async () => {
  try {
    const response = await axios.get(`${getBaseUrl()}/api/temples`)
    return response.data
  } catch (error) {
    console.log('Error fetching temple data:', error)
  }
}

export const getTouchByDate = async (month = new Date().getMonth() + 1) => {
  const year = new Date().getFullYear()
  try {
    const response = await axios.get(`${getBaseUrl()}/api/touches-by-date`, {
      params: { month, year },
    })
    return response.data
  } catch (error) {
    console.log('Error fetching touch data:', error)
  }
}

export const recordTempleTouch = async (templeId, date) => {
  try {
    const response = await axios.post(`${getBaseUrl()}/api/add-touch`, {
      temple_id: templeId,
      date_of_touch: date,
    })
    return response.data
  } catch (error) {
    console.log('Error recording temple touch:', error)
  }
}
