import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FaHeart } from 'react-icons/fa'
import classNames from 'classnames'
import moment from 'moment'
import '@/styles/index.scss' // Ensure you import your SCSS file

const RandomPoints = ({ data, divRef, temples }) => {
  const [points, setPoints] = useState([])
  const [hoveredPoint, setHoveredPoint] = useState(null)
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    const parsedPoints = data.map((touch) => {
      const [x, y] = touch.points.split(',').map(Number)
      return { x, y }
    })
    setPoints(parsedPoints)
  }, [data])

  const handleMouseEnter = (index, point) => {
    setHoveredPoint(index)
    setModalPosition({ x: point.x + 10, y: point.y + 10 }) // Add offset to position the modal next to the point
  }

  const handleMouseLeave = () => {
    setHoveredPoint(null)
  }

  const getTempleById = (templeId) => {
    return temples.find((temple) => temple.id === templeId)
  }

  const hoveredTemple =
    hoveredPoint !== null ? getTempleById(data[hoveredPoint].temple_id) : null

  const adjustModalPosition = (x, y, width, height) => {
    const screenWidth = window.innerWidth
    const screenHeight = window.innerHeight

    if (x + width > screenWidth) {
      x = screenWidth - width - 25 // 25px padding from the edge
    }
    if (y + height > screenHeight) {
      y = screenHeight - height - 25 // 25px padding from the edge
    }
    if (x < 0) {
      x = 10 // 10px padding from the edge
    }
    if (y < 0) {
      y = 10 // 10px padding from the edge
    }

    return { x, y }
  }

  const adjustedModalPosition = adjustModalPosition(
    modalPosition.x,
    modalPosition.y,
    200,
    200
  ) // Assuming modal width and height are 200px

  const adjustTooltipPosition = (point) => {
    const tooltipWidth = 200 // Adjust based on your tooltip width
    const tooltipHeight = 150 // Adjust based on your tooltip height
    const offset = 20

    let x = point.x + offset
    let y = point.y + offset

    if (x + tooltipWidth > window.innerWidth) {
      x = window.innerWidth - tooltipWidth - offset
    }

    if (y + tooltipHeight > window.innerHeight) {
      y = window.innerHeight - tooltipHeight - offset
    }

    setAdjustedModalPosition({ x, y })
  }

  return (
    <div>
      {points.map((point, index) => (
        <div
          className={classNames('dataPoint', {
            hovered: hoveredPoint === index,
          })}
          key={index}
          style={{
            position: 'absolute',
            left: `${point.x}px`,
            top: `${point.y}px`,
          }}
          onMouseEnter={() => handleMouseEnter(index, point)}
          onMouseLeave={handleMouseLeave}
        >
          <FaHeart />
        </div>
      ))}
      {hoveredPoint !== null && (
        <div
          className="modal-points"
          style={{
            left: `${adjustedModalPosition.x + 20}px`,
            top: `${adjustedModalPosition.y + 20}px`,
          }}
          onMouseEnter={() => setHoveredPoint(hoveredPoint)}
          onMouseLeave={handleMouseLeave}
        >
          <p style={{ fontSize: '18px' }}>{hoveredTemple.name}</p>
          <img
            src={hoveredTemple.img_location}
            alt={hoveredTemple.name}
            style={{ width: '100%', height: '100px' }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = 'path/to/default-image.jpg'
              currentTarget.alt = 'temple image not found'
            }}
          />
          <p>
            Visited on:{' '}
            {moment(data[hoveredPoint].date_of_touch, 'DD-MM-YYYY').format(
              'LL'
            )}
          </p>
        </div>
      )}
    </div>
  )
}

RandomPoints.propTypes = {
  data: PropTypes.array.isRequired,
  divRef: PropTypes.object.isRequired,
  temples: PropTypes.array.isRequired,
}

export default RandomPoints
