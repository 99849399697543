import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { getTouchByDate, recordTempleTouch } from './templeData'
import { TouchesContext } from '../context/TouchesContext'

const AddTouch = ({ handleCloseModal, temples, load, setLoad }) => {
  const [selectedTemple, setSelectedTemple] = useState(null)
  const [date, setDate] = useState('')
  const [points, setPoints] = useState('')

  const { setTouches, currentDate } = useContext(TouchesContext);

  const templeOptions =
    temples &&
    temples?.map((temple) => ({ value: temple.id, label: temple.name }))

  const handleTempleChange = (selectedOption) => {
    setSelectedTemple(selectedOption)
  }

  const handleSubmit = async () => {
    event.preventDefault()
    setLoad(true)
    try {
      await recordTempleTouch(selectedTemple.value, date)
      const data = await getTouchByDate(currentDate)
      console.log({data, currentDate})
      setTouches(data)
    } catch (error) {
      console.log('Error recording temple touch:', error)
    } finally {
      setLoad(false)
      if (handleCloseModal) {
        handleCloseModal()
      }
    }
  }

  const handleClose = (e) => {
    if (e.target.className === 'modal') {
      handleCloseModal()
    }
  }

  return (
    <div className="modal" onClick={handleClose}>
      <div className="modal-content">
        <div className="modal-header">
          <span className="close" onClick={handleCloseModal} role="button">
            &times;
          </span>
          <h2>Add Touch</h2>
        </div>
        <form>
          <label>
            Temple Visited:
            <Select
              className="select"
              options={templeOptions}
              value={selectedTemple}
              onChange={handleTempleChange}
              placeholder="Select a temple"
              isSearchable
            />
          </label>
          <br />
          <label className="date">
            Date of Visit:
            <input
              type="date"
              name="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </label>
          <br />
        </form>
        <button onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  )
}

AddTouch.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  temples: PropTypes.array.isRequired,
  load: PropTypes.bool.isRequired,
  setLoad: PropTypes.func.isRequired,
  handleNewTouch: PropTypes.func.isRequired,
}

export default AddTouch
