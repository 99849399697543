import { useState } from 'react'
import { TouchesContext } from '../../context/TouchesContext';
export const TouchesProvider = ({ children }) => {
  const [touches, setTouches] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date().getMonth() + 1);

  return (
      <TouchesContext.Provider value={{ touches, setTouches, currentDate, setCurrentDate }}>
        {children}
      </TouchesContext.Provider>
    )
}