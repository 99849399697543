import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App.jsx'
import { TouchesProvider } from './assets/provider/TouchesProvider.jsx'

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <TouchesProvider>
      <App />
    </TouchesProvider>
  </StrictMode>,
)
